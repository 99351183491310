import React from 'react'
import Home from '../comonents/mainPage/Home'

const Pages = () => {
  return (
    <>
    <Home />
    </>
  )
}

export default Pages
