import React from 'react'
import Sdata from './Sdate'
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

const SlideCart = () => {
    
        // const settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1
        //   }
       
    
   
  return (
  
     <>
     {/* <Slider {...settings}> */}
    {Sdata.map((value, index) => {
        return(
      <div className="box d_flex top" key={index}>
            <div className="left">
            <h1>{value.title}</h1>
            <p>{value.desc}</p>
            <button className='btn-primary'>Visit Colections</button>
            </div>
            <div className='right'>
                <img src={value.cover} alt="" />
            </div>
         </div>

        )
    })}
    {/* </Slider> */}
    </>
    
   
    
  );
}

export default SlideCart
